<template>
  <div class="about pl-9 pr-9 header-clear">
    <v-row class="pb-9 wrapper">
      <h2 class="mt-12">Услуги</h2>
    </v-row>
    <v-row class="pb-9 d-block wrapper">
      <h4 class="mb-6">Интернет</h4>
      <v-data-table
        :headers="headers"
        :items="plans"
        class="elevation-1 table"
        hide-default-footer
      >
        <template #item.contract="{ value }">
            <a :href="`${value}`" target="_blank">
              Свали
            </a>
        </template>
      </v-data-table>
    </v-row>
    <v-row class="pb-9 d-block wrapper">
      <h4 class="mb-6">Телевизия</h4>
      <v-data-table
        :headers="headersTv"
        :items="tvplans"
        class="elevation-1 table"
        hide-default-footer
      >
        <template #item.channels="{ value }">
            <router-link to="/services-tv-channels">
              {{ value }}
            </router-link>
        </template>
        <template #item.contract="{ value }">
            <a :href="`${value}`" target="_blank">
              Свали
            </a>
        </template>
      </v-data-table>
    </v-row>
    <v-row class="pb-9 d-block wrapper">
      <h4 class="mb-6">Комбиниран</h4>
      <v-data-table
        :headers="headersCombo"
        :items="comboPlans"
        class="elevation-1 table"
        hide-default-footer
      >
        <template #item.channels="{ value }">
            <router-link to="/services-tv-channels">
              {{ value }}
            </router-link>
        </template>
      </v-data-table>
    </v-row>
    <v-row class="pb-9 d-block wrapper">
      <h4 class="mt-6 mb-6">Параметри и условия за ползване:</h4>
      <ul 
      >
        <li class="mb-1"
          v-for="list in lists"
          :key="list.id"
        >{{ list.text }}</li>
      </ul>
    </v-row>
    <v-row class="d-block mt-6 mb-12 wrapper">
      <p class="mb-0">P2P си запазва правото да променя цени по свое усмотрение, като това не се отразява на настоящите абонати.</p>
      <p class="mt-0 mb-6">Канали и тарифи, които не са включени в ценовата листа се договарят допълнително.</p>
      <a href="/krc.pdf" target="_blank" class="text--point">* Използван интерфейс</a>
    </v-row>
  </div>
</template>
<style lang="scss">
  .table {
    max-width: 70%;
  }
  .text--point {
    color: #E66A1E !important;
  }
  .dialog-image {
    object-fit: contain;
  }
  .channel-container {
    width: 25%;
    @media screen and(max-width: 960px) {
      width: 33%;
    }
    @media screen and(max-width: 450px) {
      width: 50%;
    }
  }
  .dialog-image {
    max-width: 70px;
    max-height: 70px;
    @media screen and(max-width: 450px) {
      max-width: 50px;
      max-height: 50px;
    }
  }
  .dialog-text {
    font-size: 24px;
    font-weight: 400;
    color: #000;
  }
  .width-100 {
    width: 100%;
  }
</style>
<script>
  export default {
    data () {
      return {
        headers: [
          {
            text: 'Абонаментен план',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Международен достъп до интернет download', value: 'download' },
          { text: 'Договор', value: 'contract' },
          { text: 'Месечна такса с включен ДДС', value: 'price' },
        ],
        headersTv: [
          {
            text: 'Абонаментен план',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'ТВ Канали', value: 'channels'},
          { text: 'Договор', value: 'contract' },
          { text: 'Месечна такса с включен ДДС', value: 'price' },
        ],
        headersCombo: [
          {
            text: 'Абонаментен план',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Международен достъп до интернет download', value: 'download' },
          { text: 'ТВ Канали', value: 'channels'},
          { text: 'Месечна такса с включен ДДС', value: 'price' },
        ],
        plans: [
          {
            name: 'Стандарт',
            download: '50 Mbit/s',
            contract: 'p2p-net-20.pdf',
            price: '20,00 лв.',
          },
          {
            name: 'Разширен',
            download: '80 Mbit/s',
            contract: 'p2p-net-30.pdf',
            price: '30,00 лв.',
          },
          {
            name: 'Разширен +',
            download: '100 Mbit/s',
            contract: 'p2p-net-40.pdf',
            price: '40,00 лв.',
          },
        ],
        tvplans: [
          {
            name: 'Стандарт ТВ',
            channels: 'Включени канали',
            contract: 'p2p-tv-Стандарт.pdf',
            price: '12,00 лв.',
          },
          {
            name: 'Разширен ТВ',
            channels: 'Включени канали',
            contract: 'p2p-tv-Разширен.pdf',
            price: '15,00 лв.',
          },
          {
            name: 'Разширен + ТВ',
            channels: 'Включени канали',
            contract: 'p2p-tv-Разширен+.pdf',
            price: '18,00 лв.',
          },
        ],
        comboPlans: [
          {
            name: 'Стандарт - Комбиниран',
            download: '50 Mbit/s',
            channels: 'Включени канали',
            price: '22,00 лв.',
          },
          {
            name: 'Разширен - Комбиниран',
            download: '50 Mbit/s',
            channels: 'Включени канали',
            price: '27,00 лв.',
          },
          {
            name: 'Разширен + Комбиниран',
            download: '50 Mbit/s',
            channels: 'Включени канали',
            price: '32,00 лв.',
          },
        ],
        lists: [
          { id: 1, text: 'Предоставя услугата достъп до интернет със следната минимална, обичайно налична, максимална и рекламирана скорост за изтегляне и качване на услугите за достъп до интернет.' },
          { id: 2, text: 'При услуга с рекламирани скорости като „максимално достижими” скорости на сваляне (download) и на качване (upload), в Mbps:Максимална скорост 95 % от рекламираните скорости.' },
          { id: 3, text: 'Обичайно налична скорост 90% от рекламираните скорости; Минималната скорост 20 % от рекламираните скорост.' },
          { id: 4, text: 'Съотношението Български към Международен Интернет е 1:1.' },
          { id: 5, text: 'Съотношението за скорост на download към upload e 1:1.' },
          { id: 6, text: 'Цената за допълнителен статичен IP адрес е 20.00 лв. на месец.' },
          { id: 7, text: 'Всички цени са с включен ДДС и са валидни от 01.07.2021 г.' },
          { id: 8, text: 'Всеки един договор позволява включване до 3 (три) крайни цифрови приемника (STB), като за всеки следващ след първия, се доплаща допълнителна месечна такса от 5.00 лв. / мес.' },
          { id: 9, text: 'За повече от 3 (три) крайни приемника, се подписва нов договор за телевизия.' },
          { id: 10, text: '029342427 телефон за техническа поддръжка.' },
        ],
        channelsListMinimal: [
          { id: 1, title: 'BNT1', url: 'BNT1.png' },
          { id: 2, title: 'BTV', url: 'BTV-logo.png' },
          { id: 3, title: 'Nova', url: 'Nova.png' },
          { id: 4, title: 'BTV Action', url: 'BTV-Action.jpg' },
          { id: 5, title: 'BTV Comedy', url: 'Btv-comedy.png' },
          { id: 6, title: 'BTV Cinema', url: 'BTV_Cinema_2016_logo.png' },
          { id: 8, title: 'BTV Lady', url: 'BTV_Lady_2016_logo.png' },
          { id: 9, title: 'RING.BG', url: 'Ring-bg.png' },
          { id: 21, title: 'Bulgaria On Air', url: 'Bulgariaonair.png' },
          { id: 23, title: 'Bloomberg TV', url: 'bloomberg.png' },
          { id: 28, title: 'BNT 2', url: 'BNT2.png' },
          { id: 29, title: 'BNT 4', url: 'BNT4.png' },
          { id: 34, title: 'TV Evropa', url: 'tv-evropa.jpg' },
          { id: 36, title: 'BG Dnes', url: 'bg-dnes.jpg' },
          { id: 37, title: 'EVROKOM', url: 'evrokom.png' },
          { id: 46, title: 'Super Toons', url: 'super-toons.jpg' },
          { id: 50, title: 'Discovery Channel', url: 'discovery-channel.png' },
          { id: 51, title: 'ID', url: 'id.png' },
          { id: 52, title: 'TLC', url: 'tlc.png' },
          { id: 55, title: 'DW', url: 'dw.png' }, 
          { id: 56, title: 'Eurosport 1', url: 'euro-sport.png' }, 
          { id: 57, title: 'Eurosport 2', url: 'euro-sport-2.jpg' },
          { id: 58, title: 'Food Network', url: 'food.png' }, 
          { id: 59, title: 'France 24', url: 'france-24.png' }, 
          // { id: 60, title: 'Russia Today HD', url: 'russia-today.png' }, 
          { id: 61, title: 'Travel Channel', url: 'travel.png' },
          { id: 62, title: 'TV5 Monde', url: 'tv5.png' }, 
          { id: 63, title: 'Алфа ТВ', url: 'alfa.jpg' }, 
          { id: 64, title: 'БСТВ', url: 'bstv.png' },
          { id: 65, title: 'Българе', url: 'bulgare.jpg' },
          { id: 66, title: 'България 24', url: 'bulgaria24.jpg' },
          { id: 67, title: 'ВТК', url: 'vtk.png' }, 
          { id: 68, title: 'Планета 4К', url: 'planeta4k.png' },
          { id: 69, title: 'Планета HD', url: 'planetahd.png' },
          { id: 70, title: 'Планета Фолк', url: 'planeta-folk.jpg' },
          { id: 71, title: 'ППТВ', url: 'pptv.jpg' }, 
          { id: 72, title: 'СИТИ ТВ', url: 'city.jpg' },
        ],
        channelsListStandard: [
          { id: 1, title: 'BNT1', url: 'BNT1.png' },
          { id: 2, title: 'BTV', url: 'BTV-logo.png' },
          { id: 3, title: 'Nova', url: 'Nova.png' },
          { id: 4, title: 'BTV Action', url: 'BTV-Action.jpg' },
          { id: 5, title: 'BTV Comedy', url: 'Btv-comedy.png' },
          { id: 6, title: 'BTV Cinema', url: 'BTV_Cinema_2016_logo.png' },
          { id: 7, title: 'Kino Nova', url: 'KinoNova.png' },
          { id: 8, title: 'BTV Lady', url: 'BTV_Lady_2016_logo.png' },
          { id: 9, title: 'RING.BG', url: 'Ring-bg.png' },
          { id: 10,  title: 'Diema', url: 'Diema_logo.png' },
          { id: 11,  title: 'Diema Family', url: 'DiemaFamily_2019.png' },
          { id: 12,  title: 'Nova Sport', url: 'NovaSport.png' },
          { id: 14,  title: 'FOX', url: 'FOX.png' },
          { id: 15,  title: 'Fox Life', url: 'fox-life.jpg' },
          { id: 16,  title: 'Fox Crime', url: 'fox-crime.jpg' },
          { id: 21,  title: 'Bulgaria On Air', url: 'Bulgariaonair.png' },
          { id: 23,  title: 'Bloomberg TV', url: 'bloomberg.png' },
          { id: 24,  title: 'National Geographic', url: 'nat-geo.png' },
          { id: 26,  title: 'Nat Geo Wild', url: 'nat-geo-wild.png' },
          { id: 28, title: 'BNT 2', url: 'BNT2.png' },
          { id: 29, title: 'BNT 4', url: 'BNT4.png' },
          { id: 36, title: 'BG Dnes', url: 'bg-dnes.jpg' },
          { id: 37, title: 'EVROKOM', url: 'evrokom.png' },
          { id: 41, title: 'Disney Channel', url: 'disney.png' },
          { id: 46, title: 'Super Toons', url: 'super-toons.jpg' },
          { id: 48, title: 'Карусель', url: 'karusel.png' },
          { id: 49, title: 'LOLLY KIDS', url: 'lolly-kids.png' },
          { id: 50, title: 'Discovery Channel', url: 'discovery-channel.png' },
          { id: 51, title: 'ID', url: 'id.png' },
          { id: 52, title: 'TLC', url: 'tlc.png' },
          { id: 53, title: 'Animal Planet', url: 'animal-planet.jpg' },
          { id: 54, title: 'History', url: 'history.jpg' },
          { id: 55, title: 'DW', url: 'dw.png' }, 
          { id: 56, title: 'Eurosport 1', url: 'euro-sport.png' }, 
          { id: 57, title: 'Eurosport 2', url: 'euro-sport-2.jpg' },
          { id: 58, title: 'Food Network', url: 'food.png' }, 
          { id: 59, title: 'France 24', url: 'france-24.png' }, 
          // { id: 60, title: 'Russia Today HD', url: 'russia-today.png' }, 
          { id: 61, title: 'Travel Channel', url: 'travel.png' },
          { id: 62, title: 'TV5 Monde', url: 'tv5.png' }, 
          { id: 63, title: 'Алфа ТВ', url: 'alfa.jpg' }, 
          { id: 64, title: 'БСТВ', url: 'bstv.png' },
          { id: 65, title: 'Българе', url: 'bulgare.jpg' },
          { id: 66, title: 'България 24', url: 'bulgaria24.jpg' },
          { id: 67, title: 'ВТК', url: 'vtk.png' }, 
          { id: 68, title: 'Планета 4К', url: 'planeta4k.png' },
          { id: 69, title: 'Планета HD', url: 'planetahd.png' },
          { id: 70, title: 'Планета Фолк', url: 'planeta-folk.jpg' },
          { id: 71, title: 'ППТВ', url: 'pptv.jpg' }, 
          { id: 72, title: 'СИТИ ТВ', url: 'city.jpg' },
          // { id: 74, title: 'BLACKDIAMONDZ', url: 'blackdiamonds.png' },
          { id: 75, title: 'BOX TV', url: 'box.png' },
          { id: 76, title: 'CCTV-4', url: 'cctv4.png' },
          // { id: 77, title: 'CGTN', url: 'cgtn.png' },
          // { id: 78, title: 'CGTN -  Español', url: 'cgtn-espanol.png' },
          // { id: 79, title: 'CGTN -  Français', url: 'cgtn-francais.png' },
          // { id: 80, title: 'CGTN -  Русский', url: 'cgtn-russian.png' },
          // { id: 81, title: 'CGTN -  العرب', url: 'cgtn-arabic.png' },
          // { id: 82, title: 'CGTN–Documentary', url: 'cgtn-documentary.png' },
          // { id: 83, title: 'CHILAYO', url: 'Chillayo_logo_last.png' },
          // { id: 84, title: 'CUBAYO', url: 'cubayo.png' },
          { id: 85, title: 'DSTV', url: 'dstv.jpg' },
          { id: 86, title: 'EDGE Sport', url: 'edgesport.png' },
          // { id: 87, title: 'FLAME', url: 'flame-tv.png' },
          // { id: 88, title: 'H&WELLNESS', url: 'wellness.png' },
          // { id: 89, title: 'HOMIE‘S', url: '' },
          { id: 90, title: 'Insight HD', url: 'insight.png' },
          // { id: 92, title: 'LUXE & LIFE', url: 'luxe.jpg' },
          { id: 93, title: 'NOVA HD', url: 'nova-hd.jpg' },
          { id: 94, title: 'Nova Sport HD', url: 'nova-sport-hd.jpg' }, 
          // { id: 95, title: 'PARTY‘S', url: 'partys.png' },
          // { id: 96, title: 'PET & PAL', url: 'pet-pal.png' },
          { id: 97, title: 'Public TV of Armenia', url: 'tv-armenia.jpg' }, 
          { id: 98, title: 'Roma TV', url: 'roma_tv.png' }, 
          { id: 99, title: 'Roma TV HD', url: 'roma_tv.png' }, 
          // { id: 100, title: 'Russia 24', url: 'russia-24.png' }, 
          // { id: 101, title: 'SMILIE', url: 'smilie.png' },
          // { id: 102, title: 'SPEED FACTOR', url: 'speed-factor.png' },
          // { id: 103, title: 'SPORTIFY', url: '' },
          { id: 104, title: 'This is Bulgaria', url: '' },
          { id: 106, title: 'Travel XP', url: 'travelxp.png' },
          { id: 107, title: 'Wness TV', url: 'wness.png' },
          // { id: 108, title: 'WOW', url: 'wow.jpg' },
          { id: 109, title: 'Агро ТВ', url: 'agro-tv.png' },
          { id: 110, title: 'Восьмой канал', url: '8.png' }, 
          { id: 111, title: 'Время', url: 'vremya.png' }, 
          { id: 112, title: 'Детский мир', url: 'detski-mir.jpg' }, 
          { id: 113, title: 'Дом кино', url: 'dom-kino.png' }, 
          { id: 114, title: 'Дом кино премиум', url: 'dom-kino-premium.png' }, 
          { id: 115, title: 'Музъiка', url: '' }, 
          { id: 116, title: 'Настоящее время', url: 'nastoyashte-vremya.png' }, 
          { id: 117, title: 'Национ. Патр. ТВ', url: '' }, 
          { id: 118, title: 'Наше любимое кино', url: 'lyubimoe-kino.png' }, 
          { id: 119, title: 'Охота и Рьiбалка', url: 'ohota.png' }, 
          { id: 120, title: '   ', url: '   ' }, 
          // { id: 121, title: 'РТР Планета', url: 'rtr.png' }, 
          // { id: 122, title: 'ТВ Центр', url: 'tvcentr.png' }, 
          { id: 123, title: 'ТВ1', url: 'tv1.png' },
          { id: 124, title: 'Тянков Ориент', url: 'orient.png' },
          { id: 125, title: 'Тянков ТВ', url: 'tiankov.png' },
          { id: 126, title: 'Фолклор ТВ', url: 'folklor.jpg' },
          { id: 127, title: 'Шансон ТВ', url: 'shanson.png' }, 
        ],
        channelsListOptimal: [
          { id: 1, title: 'BNT1', url: 'BNT1.png' },
          { id: 2, title: 'BTV', url: 'BTV-logo.png' },
          { id: 3, title: 'Nova', url: 'Nova.png' },
          { id: 4, title: 'BTV Action', url: 'BTV-Action.jpg' },
          { id: 5, title: 'BTV Comedy', url: 'Btv-comedy.png' },
          { id: 6, title: 'BTV Cinema', url: 'BTV_Cinema_2016_logo.png' },
          { id: 7, title: 'Kino Nova', url: 'KinoNova.png' },
          { id: 8, title: 'BTV Lady', url: 'BTV_Lady_2016_logo.png' },
          { id: 9, title: 'RING.BG', url: 'Ring-bg.png' },
          { id: 10,  title: 'Diema', url: 'Diema_logo.png' },
          { id: 11,  title: 'Diema Family', url: 'DiemaFamily_2019.png' },
          { id: 12,  title: 'Nova Sport', url: 'NovaSport.png' },
          { id: 13,  title: 'BTN 1 HD', url: 'BNT1.png' },
          { id: 14,  title: 'FOX', url: 'FOX.png' },
          { id: 15,  title: 'Fox Life', url: 'fox-life.jpg' },
          { id: 16,  title: 'Fox Crime', url: 'fox-crime.jpg' },
          { id: 17,  title: 'FOX HD', url: 'fox-hd.png' },
          { id: 18,  title: 'Fox Life HD', url: 'FOX_life_HD.png' },
          { id: 19,  title: 'Fox Crime HD', url: 'fox-crime.jpg' },
          { id: 20,  title: 'TV1000', url: 'tv-1000.png' },
          { id: 21,  title: 'Bulgaria On Air', url: 'Bulgariaonair.png' },
          { id: 22,  title: 'Bloomberg TV Bulgaria HD', url: 'bloombergbulgaria.png' },
          { id: 23,  title: 'Bloomberg TV', url: 'bloomberg.png' },
          { id: 24,  title: 'National Geographic', url: 'nat-geo.png' },
          { id: 25,  title: 'National Geographic HD', url: 'nat-geo-hd.png' },
          { id: 26,  title: 'Nat Geo Wild', url: 'nat-geo-wild.png' },
          { id: 27,  title: 'Nat Geo Wild HD', url: 'nat-geo-wild-hd.png' },
          { id: 28, title: 'BNT 2', url: 'BNT2.png' },
          { id: 29, title: 'BNT 4', url: 'BNT4.png' },
          { id: 30, title: 'BNT 2 HD', url: 'BNT2.png' },
          { id: 31, title: 'BNT 3 HD', url: 'BNT3.png' },
          { id: 32, title: 'BNT 4 HD', url: 'BNT4.png' },
          { id: 33, title: 'Nova News', url: 'nova-news.png' },
          { id: 34, title: 'TV Evropa', url: 'tv-evropa.jpg' },
          { id: 35, title: 'The World', url: 'world-channel.png' },
          { id: 36, title: 'BG Dnes', url: 'bg-dnes.jpg' },
          { id: 37, title: 'EVROKOM', url: 'evrokom.png' },
          { id: 38, title: '24 Kitchen', url: '24-kitchen.png' },
          { id: 39, title: '24 Kitchen HD', url: '24-kitchen.png' },
          { id: 40, title: 'Baby TV', url: 'baby-tv.jpg' },
          { id: 41, title: 'Disney Channel', url: 'disney.png' },
          { id: 42, title: 'Disney Jr.', url: 'disney-junior.png' },
          { id: 43, title: 'Nickelodeon', url: 'nickelodeon.png' },
          { id: 44, title: 'Nick Jr.', url: 'nick-jr.png' },
          { id: 45, title: 'ECTV for Kids HD', url: 'ectv-kids.png' },
          { id: 46, title: 'Super Toons', url: 'super-toons.jpg' },
          { id: 47, title: 'Nicktoons', url: 'nicktoons.png' },
          { id: 48, title: 'Карусель', url: 'karusel.png' },
          { id: 49, title: 'LOLLY KIDS', url: 'lolly-kids.png' },
          { id: 50, title: 'Discovery Channel', url: 'discovery-channel.png' },
          { id: 51, title: 'ID', url: 'id.png' },
          { id: 52, title: 'TLC', url: 'tlc.png' },
          { id: 53, title: 'Animal Planet', url: 'animal-planet.jpg' },
          { id: 54, title: 'History', url: 'history.jpg' },
          { id: 55, title: 'DW', url: 'dw.png' }, 
          { id: 56, title: 'Eurosport 1', url: 'euro-sport.png' }, 
          { id: 57, title: 'Eurosport 2', url: 'euro-sport-2.jpg' },
          { id: 58, title: 'Food Network', url: 'food.png' }, 
          { id: 59, title: 'France 24', url: 'france-24.png' }, 
          // { id: 60, title: 'Russia Today HD', url: 'russia-today.png' }, 
          { id: 61, title: 'Travel Channel', url: 'travel.png' },
          { id: 62, title: 'TV5 Monde', url: 'tv5.png' }, 
          { id: 63, title: 'Алфа ТВ', url: 'alfa.jpg' }, 
          { id: 64, title: 'БСТВ', url: 'bstv.png' },
          { id: 65, title: 'Българе', url: 'bulgare.jpg' },
          { id: 66, title: 'България 24', url: 'bulgaria24.jpg' },
          { id: 67, title: 'ВТК', url: 'vtk.png' }, 
          { id: 68, title: 'Планета 4К', url: 'planeta4k.png' },
          { id: 69, title: 'Планета HD', url: 'planetahd.png' },
          { id: 70, title: 'Планета Фолк', url: 'planeta-folk.jpg' },
          { id: 71, title: 'ППТВ', url: 'pptv.jpg' }, 
          { id: 72, title: 'СИТИ ТВ', url: 'city.jpg' },
          // { id: 74, title: 'BLACKDIAMONDZ', url: 'blackdiamonds.png' },
          { id: 75, title: 'BOX TV', url: 'box.png' },
          { id: 76, title: 'CCTV-4', url: 'cctv4.png' },
          // { id: 77, title: 'CGTN', url: 'cgtn.png' },
          // { id: 78, title: 'CGTN -  Español', url: 'cgtn-espanol.png' },
          // { id: 79, title: 'CGTN -  Français', url: 'cgtn-francais.png' },
          // { id: 80, title: 'CGTN -  Русский', url: 'cgtn-russian.png' },
          // { id: 81, title: 'CGTN -  العرب', url: 'cgtn-arabic.png' },
          // { id: 82, title: 'CGTN–Documentary', url: 'cgtn-documentary.png' },
          // { id: 83, title: 'CHILAYO', url: 'Chillayo_logo_last.png' },
          // { id: 84, title: 'CUBAYO', url: 'cubayo.png' },
          { id: 85, title: 'DSTV', url: 'dstv.jpg' },
          { id: 86, title: 'EDGE Sport', url: 'edgesport.png' },
          // { id: 87, title: 'FLAME', url: 'flame-tv.png' },
          // { id: 88, title: 'H&WELLNESS', url: 'wellness.png' },
          // { id: 89, title: 'HOMIE‘S', url: '' },
          { id: 90, title: 'Insight HD', url: 'insight.png' },
          // { id: 92, title: 'LUXE &LIFE', url: 'luxe.jpg' },
          { id: 93, title: 'NOVA HD', url: 'nova-hd.jpg' },
          { id: 94, title: 'Nova Sport HD', url: 'nova-sport-hd.jpg' }, 
          // { id: 95, title: 'PARTY‘S', url: 'partys.png' },
          // { id: 96, title: 'PET & PAL', url: 'pet-pal.png' },
          { id: 97, title: 'Public TV of Armenia', url: 'tv-armenia.jpg' }, 
          { id: 98, title: 'Roma TV', url: 'roma_tv.png' }, 
          { id: 99, title: 'Roma TV HD', url: 'roma_tv.png' }, 
          // { id: 100, title: 'Russia 24', url: 'russia-24.png' }, 
          // { id: 101, title: 'SMILIE', url: 'smilie.png' },
          // { id: 102, title: 'SPEED FACTOR', url: 'speed-factor.png' },
          // { id: 103, title: 'SPORTIFY', url: '' },
          { id: 104, title: 'This is Bulgaria', url: '' },
          { id: 106, title: 'Travel XP', url: 'travelxp.png' },
          { id: 107, title: 'Wness TV', url: 'wness.png' },
          // { id: 108, title: 'WOW', url: 'wow.jpg' },
          { id: 109, title: 'Агро ТВ', url: 'agro-tv.png' },
          { id: 110, title: 'Восьмой канал', url: '8.png' }, 
          { id: 111, title: 'Время', url: 'vremya.png' }, 
          { id: 112, title: 'Детский мир', url: 'detski-mir.jpg' }, 
          { id: 113, title: 'Дом кино', url: 'dom-kino.png' }, 
          { id: 114, title: 'Дом кино премиум', url: 'dom-kino-premium.png' }, 
          { id: 115, title: 'Музъiка', url: '' }, 
          { id: 116, title: 'Настоящее время', url: 'nastoyashte-vremya.png' }, 
          { id: 117, title: 'Национ. Патр. ТВ', url: '' }, 
          { id: 118, title: 'Наше любимое кино', url: 'lyubimoe-kino.png' }, 
          { id: 119, title: 'Охота и Рьiбалка', url: 'ohota.png' }, 
          { id: 120, title: '   ', url: '   ' }, 
          // { id: 121, title: 'РТР Планета', url: 'rtr.png' }, 
          // { id: 122, title: 'ТВ Центр', url: 'tvcentr.png' }, 
          { id: 123, title: 'ТВ1', url: 'tv1.png' },
          { id: 124, title: 'Тянков Ориент', url: 'orient.png' },
          { id: 125, title: 'Тянков ТВ', url: 'tiankov.png' },
          { id: 126, title: 'Фолклор ТВ', url: 'folklor.jpg' },
          { id: 127, title: 'Шансон ТВ', url: 'shanson.png' }, 
          { id: 128, title: '3sat HD', url: '3sat.png' },
          { id: 129, title: 'AI Jazeera', url: 'al.png' },
          { id: 130, title: 'Animal Planet HD', url: 'aphd.png' },
          { id: 131, title: 'ARD HD', url: 'ard.png' },
          { id: 132, title: 'Arirang TV', url: 'arirang.png' },
          { id: 133, title: 'Arte', url: 'arte.png' },
          { id: 134, title: 'Balkanika', url: 'balkanika.png' },
          { id: 135, title: 'Balkanika HD', url: 'balkanika.png' },
          { id: 136, title: 'BG Music Channel', url: 'bgmusic.jpg' },
          { id: 137, title: 'BG Music Channel HD', url: 'bgmusic.jpg' },
          { id: 138, title: 'bTV Action HD', url: 'actionhd.jpg' },
          { id: 139, title: 'bTV Cinema HD', url: 'BTV_Cinema_2016_logo.png' },
          { id: 140, title: 'bTV Comedy HD', url: 'Btv-comedy.png' },
          { id: 141, title: 'bTV HD', url: 'btvhd.jpg' },
          { id: 142, title: 'Bulgaria On Air HD', url: 'Bulgariaonair.png' },
          { id: 143, title: 'CentoxCento', url: 'cento.png' },
          { id: 144, title: 'Discovery Channel HD', url: 'dischd.jpg' },
          { id: 145, title: 'DM Sat', url: 'dm_sat.png' },
          { id: 146, title: 'Eurosport 1 HD', url: 'euro-sport-hd.png' },
          { id: 147, title: 'Eurosport 2 HD', url: 'euro-sport-2-hd.png' },
          { id: 148, title: 'Fen Folk', url: 'fen_folk.png' },
          { id: 149, title: 'Fen TV', url: 'fen.png' },
          { id: 150, title: 'Fen TV HD', url: 'fen-hd.png' },
          { id: 151, title: 'Food Network HD', url: 'food-hd.png' },
          { id: 152, title: 'France 2', url: 'france-2.png' },
          { id: 153, title: 'France 3', url: 'france-3.png' },
          { id: 154, title: 'Hit Mix Music', url: 'hit.png' },
          { id: 155, title: 'futureONE HD', url: 'FutureOne.png' },
          { id: 156, title: 'newsONE HD', url: 'newsOne.png' },
          { id: 157, title: 'ID HD', url: 'id-hd.png' },
          { id: 158, title: 'KCN 2 Music', url: 'kcn-music2.png' },
          { id: 159, title: 'KHL', url: 'khl.png' },
          { id: 160, title: 'KHL HD', url: 'khl-hd.png' },
          { id: 161, title: 'KiKa HD', url: 'kika_hd.png' },
          { id: 162, title: 'Magic TV', url: 'magic.jpg' },
          { id: 163, title: 'Movistar', url: 'movistar.png' },
          { id: 164, title: 'MTV', url: 'mtv.jpg' },
          { id: 165, title: 'MTV 80’s', url: 'mtv-80.jpg' },
          { id: 166, title: 'MTV 90’s', url: 'mtv-90.png' },
          { id: 167, title: 'MTV Hits', url: 'mtv-hits.png' },
          { id: 168, title: 'PassionXXX', url: 'passion.jpg' },
          { id: 169, title: 'PassionXXX 2', url: 'passion.jpg' },
          { id: 170, title: 'Penthouse Gold', url: 'pent-gold.png' },
          { id: 171, title: 'Penthouse Passion', url: 'pent-passion.png' },
          { id: 172, title: 'Penthouse Quickies', url: 'pent-quickies.png' },
          { id: 173, title: 'Pinko Club', url: 'pinko.png' },
          { id: 174, title: 'Pro 7', url: 'pro7.png' },
          { id: 175, title: 'RAI DUE', url: 'rai2.png' },
          { id: 176, title: 'RAI UNO', url: 'rai1.png' },
          { id: 177, title: 'RING.BG HD', url: 'ringhd.png' },
          { id: 178, title: 'RTVi', url: 'rtvi.png' },
          // { id: 179, title: 'Russia Today Docu HD', url: 'rtd-d.png' },
          { id: 180, title: 'Sat1', url: 'sat1.png' },
          { id: 181, title: 'The Voice', url: 'thevoice.png' },
          { id: 182, title: 'TLC HD', url: 'tlchd.png' },
          { id: 183, title: 'Travel Channel HD', url: 'travel-hd.jpg' },
          { id: 184, title: 'TRT', url: 'trt.jpg' },
          { id: 185, title: 'TVE', url: 'tve.png' },
          { id: 186, title: 'UATV', url: 'uatv.jpg' },
          { id: 187, title: 'VH1/ MTV 00’s', url: 'mtv-00s.png' },
          { id: 188, title: 'Viasat Explore', url: 'viasat.png' },
          { id: 189, title: 'Viasat Explore HD', url: 'viasathd.png' },
          { id: 190, title: 'Viasat History', url: 'viasat-h.jpg' },
          { id: 191, title: 'Viasat History HD', url: 'viasathistoryhd.png' },
          { id: 192, title: 'Viasat Nature', url: 'Viasat_Nature.png' },
          { id: 193, title: 'Viasat Nature HD', url: 'viasatnaturehd.png' },
          { id: 194, title: 'ZDF HD', url: 'zdf.png' },
          { id: 195, title: 'Българе HD', url: 'bulgare.jpg' },
          { id: 196, title: 'Мир Сериала', url: 'Mir_seriala.png' },
          { id: 197, title: '   ', url: '   ' },
          { id: 198, title: 'Телекафе', url: 'telekafe.png' },
          { id: 199, title: 'Фолклор ТВ HD', url: 'folklor.jpg' },
        ],
        dialog: false,
        showChannels: ''
      }
    },
    methods: {
      handleChannelsClick(e) {
        if(e.path[0].innerText === '35+') {
          this.showChannels = 'minimal'
          this.dialog = true;
        } else if(e.path[0].innerText === '100+') {
          this.showChannels = 'standard'
          this.dialog = true;
        } else {
          this.showChannels = 'optimal'
          this.dialog = true;
        }
        return
      },
      handleChannelsClose() {
        this.showChannels = ''
        this.dialog = false
      }
    }
  }
</script>