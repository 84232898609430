var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about pl-9 pr-9 header-clear"},[_c('v-row',{staticClass:"pb-9 wrapper"},[_c('h2',{staticClass:"mt-12"},[_vm._v("Услуги")])]),_c('v-row',{staticClass:"pb-9 d-block wrapper"},[_c('h4',{staticClass:"mb-6"},[_vm._v("Интернет")]),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headers,"items":_vm.plans,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.contract",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":("" + value),"target":"_blank"}},[_vm._v(" Свали ")])]}}])})],1),_c('v-row',{staticClass:"pb-9 d-block wrapper"},[_c('h4',{staticClass:"mb-6"},[_vm._v("Телевизия")]),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headersTv,"items":_vm.tvplans,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.channels",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":"/services-tv-channels"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.contract",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":("" + value),"target":"_blank"}},[_vm._v(" Свали ")])]}}])})],1),_c('v-row',{staticClass:"pb-9 d-block wrapper"},[_c('h4',{staticClass:"mb-6"},[_vm._v("Комбиниран")]),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headersCombo,"items":_vm.comboPlans,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.channels",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":"/services-tv-channels"}},[_vm._v(" "+_vm._s(value)+" ")])]}}])})],1),_c('v-row',{staticClass:"pb-9 d-block wrapper"},[_c('h4',{staticClass:"mt-6 mb-6"},[_vm._v("Параметри и условия за ползване:")]),_c('ul',_vm._l((_vm.lists),function(list){return _c('li',{key:list.id,staticClass:"mb-1"},[_vm._v(_vm._s(list.text))])}),0)]),_c('v-row',{staticClass:"d-block mt-6 mb-12 wrapper"},[_c('p',{staticClass:"mb-0"},[_vm._v("P2P си запазва правото да променя цени по свое усмотрение, като това не се отразява на настоящите абонати.")]),_c('p',{staticClass:"mt-0 mb-6"},[_vm._v("Канали и тарифи, които не са включени в ценовата листа се договарят допълнително.")]),_c('a',{staticClass:"text--point",attrs:{"href":"/krc.pdf","target":"_blank"}},[_vm._v("* Използван интерфейс")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }